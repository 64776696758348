body, html, #gameCanvas, .start-button, .touch-controls button {
  font-family: 'Press Start 2P', cursive;
}

#gameCanvas {
  background-color: #0a0a0a00;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the canvas */
  padding: 0;
  touch-action: none; /* Prevent touch zooming */
  user-select: none; /* Prevent text selection */
}

.start-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.start-button:hover {
  background-color: #00cc00;
}

.touch-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.touch-controls button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 5px;
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  touch-action: manipulation; /* Prevent delay in touch input */
}

.touch-controls button:hover {
  background-color: #00cc00;
}

.level-text {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  color: #00ff00;
  z-index: 10;
}
