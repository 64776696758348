.news-section {
  position: relative;
  padding-bottom: 50px; /* Space for carousel controls */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.typed-text-container {
  width: 80%;
  min-width: 300px; /* Ensure a minimum width */
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  position: relative;
}

.typed-text-line {
  color: #ffffff;
  font-family: 'Press Start 2P', cursive;
  margin: 1px 0;
  text-shadow: 0 0 2px #000000, 0 0 4px #000000, 0 0 6px #000000;
  white-space: pre-wrap;
  transition: text-shadow 0.2s ease-in-out;
  line-height: 1.5;
  font-size: 20px;
}

.typed-text-link {
  color: #ff4500;
  font-family: 'Press Start 2P', cursive;
  text-decoration: underline;
  margin-top: 10px;
}

.typed-text-link:hover {
  text-decoration: none;
  color: #ff6347;
}

/* Arrows */
.arrow {
  font-size: 24px;
  color: #00ff00;
  cursor: pointer;
  user-select: none;
  padding: 20px; /* Increase the clickable area */
}

.left-arrow {
  position: absolute;
  left: 0px; /* Default distance from the text container */
}

.right-arrow {
  position: absolute;
  right: 0px; /* Default distance from the text container */
}

/* Carousel controls */
.carousel-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.carousel-counter {
  font-family: 'Press Start 2P', cursive;
  color: #00ff00;
  margin-right: 10px;
}

.carousel-dots {
  display: flex;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-dot.active {
  background-color: #056e05;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .typed-text-line {
    font-size: 13px;
  }

  .typed-text-container {
    min-width: 260px; /* Ensure a reasonable minimum width for smaller screens */
    max-width: 90%; /* Allow some flexibility */
    min-height: 120px;
  }

  .arrow {
    font-size: 20px;
    padding: 5px;
  }

  .left-arrow {
    left: 15px; /* Wider distance on smaller screens */
  }

  .right-arrow {
    right: 15px; /* Wider distance on smaller screens */
  }
}

@media (max-width: 480px) {
  .typed-text-line {
    font-size: 10px;
  }

  .typed-text-container {
    min-width: 240px; /* Further reduced minimum width for very small screens */
    max-width: 90%;
    min-height: 100px;
  }

  .arrow {
    font-size: 18px;
    padding: 3px;
  }

  .left-arrow {
    left: -15px; /* Even wider distance on very small screens */
  }

  .right-arrow {
    right: -15px; /* Even wider distance on very small screens */
  }
}
