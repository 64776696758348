/* Add this new class to handle the hidden state */
.hidden {
  display: none !important;
}

/* Existing styles */

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh); /* Ensure bombs fall until the footer */
  }
}

.background-bomb {
  background-image: url('../assets/bomb.png'); /* Ensure you have the correct path */
  z-index: 0; /* Ensure bombs are behind the game elements */
  position: absolute;
}

/* Global styles to apply the font */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: url('../assets/glman123_space_background_nova_black_and_green_only_stars_--a_d893d082-e74e-47ae-9c09-25c78a45ff59_0.png') center/cover no-repeat;
  font-family: 'Press Start 2P', cursive; /* Apply font globally */
}

/* Container styles */
.landing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00ff00;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  padding-top: 70px;
}

/* Background element styles */
.background-element {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  animation: moveRandom infinite;
  z-index: 0;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
}

/* Player element styles */
.player-element {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0; /* Ensure the player element is behind the game elements */
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  transform: translateX(0); /* Ensure initial transform */
}

.bullet-element {
  position: absolute;
  background-color: yellow;
  width: 5px;
  height: 10px;
  z-index: 2;
  transition: top 0.1s linear, left 0.1s linear; /* Smooth transition for movement */
}

/* Hiding elements when background only */
.landing-page.background-only .background-element {
  z-index: 1;
}

.landing-page.background-only .content,
.landing-page.background-only .grid-container,
.landing-page.background-only .features,
.landing-page.background-only .call-to-action,
.landing-page.background-only .section,
.landing-page.background-only .contract-address {
  display: none;
}

.landing-page.background-only .game-section {
  margin-top: 1500px;
}

/* Animation for background elements */
@keyframes moveRandom {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20px, -20px);
  }
  50% {
    transform: translate(-20px, 20px);
  }
  75% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  @keyframes moveRandom {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(10px, -10px);
    }
    50% {
      transform: translate(-10px, 10px);
    }
    75% {
      transform: translate(10px, 10px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .game-section {
    margin-top: 100px;
  }

  /* Adjust contract address styling for smaller screens */
  .contract-address {
    font-size: 12px; /* Smaller text size */
    padding: 8px 12px; /* Adjust padding */
  }

  .contract-address p {
    font-size: 12px; /* Smaller text size */
  }

  .contract-address .contract-label {
    font-size: 10px; /* Smaller label size */
  }
}

/* Content styles */
.content {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 1145px;
  z-index: 1;
  font-family: 'Press Start 2P', cursive; /* Ensure font is applied */
}

/* Title styles */
.title {
  font-size: 48px;
  margin-bottom: 20px;
}

/* Button styles */
.cta-button {
  font-size: 24px;
  padding: 10px 20px;
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive; /* Ensure font is applied */
}

.cta-button:hover {
  background-color: #00cc00;
}

/* Grid container styles */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.grid-item {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  z-index: 1;
}

.grid-item.image-container {
  position: relative; /* Ensure this container is relative for positioning the image */
}

/* Grid title styles */
.grid-title {
  font-family: 'Press Start 2P', cursive;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #00ff00; /* Keep the title green */
}

/* Grid list styles */
.grid-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
  color: white;
  font-size: 17px; /* Smaller font size */
  text-align: left; /* Left justified */
  line-height: 1.6; /* Increased line height for better spacing */
}

.grid-list li {
  margin-bottom: 15px;
  padding: 5px 0; /* Add padding between lines */
  word-break: break-word; /* Ensure text breaks within words if too long */
}

/* Grid image styles */
.grid-image {
  position: absolute; /* Ensure the image is positioned absolutely within the container */
  width: 50px; /* Set a fixed width for the image */
  height: 50px; /* Set a fixed height for the image */
  animation: floatInGrid 75s infinite ease-in-out; /* Increased duration for smoother transitions */
}

/* Chart styles */
#pieChart {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

/* Features styles */
.features {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.feature {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 48%;
  z-index: 1;
}

/* Section styles */
.section {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 1145px;
  margin: 20px 0;
  z-index: 2;
}

.text-image-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-image-section.reverse {
  flex-direction: row-reverse;
}

.section-image {
  max-width: 100%;
  height: auto;
}

.section-title {
  font-size: 36px;
  margin-bottom: 20px;
}

/* Call to action styles */
.call-to-action {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 1145px;
  margin: 20px 0;
  z-index: 2;
}

/* Game section styles */
.game-section {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  margin-bottom: 0; /* Remove bottom margin */
  z-index: 3; /* Ensure the game section is above the player */
  position: relative; /* Ensure it is relative for the start button */
  box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.5); /* Green shadow effect */
}

.footer {
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-top: 3px solid green;
  width: 100%;
  z-index: 1;
}

#gameCanvas {
  background-color: #000;
  max-width: 100%;
  height: auto;
  z-index: 3; /* Ensure the canvas is above the player */
}

.touch-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.touch-controls button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 5px;
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive; /* Ensure font is applied */
}

.touch-controls button:hover {
  background-color: #00cc00;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .image-container {
    width: auto;
    height: 387px;
  }

  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    width: 100%;
    margin-bottom: 20px;
  }

  .text-image-section {
    flex-direction: column;
  }

  .text-image-section.reverse {
    flex-direction: column;
  }

  .section-image {
    width: 100%;
  }

  /* Adjust contract address styling for smaller screens */
  .contract-address {
    font-size: 12px; /* Smaller text size */
    padding: 8px 12px; /* Adjust padding */
  }

  .contract-address p {
    font-size: 12px; /* Smaller text size */
  }

  .contract-address .contract-label {
    font-size: 10px; /* Smaller label size */
  }
}

.player-container {
  position: absolute;
  z-index: 2;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 60px;
  margin: 0;
  padding: 0;
}

/* Game over styles */
.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  color: #00ff00;
  padding: 20px;
  border: 3px solid green;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}

.start-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  font-family: 'Press Start 2P', cursive; /* Ensure font is applied */
}

.start-button:hover {
  background-color: #00cc00;
}

.game-over-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-content button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.typed-text-container {
  width: 100%;
  height: 150px; /* Adjust this value to set the fixed height */
  display: flex;
  flex-direction: column; /* Stack lines vertically */
  align-items: center;
  justify-content: center;
}

.typed-text-line {
  color: #00ff00;
  font-family: 'Press Start 2P', cursive;
  margin: 1px 0; /* Add spacing between lines */
  text-shadow: 0 0 2px #000000, 0 0 4px #000000, 0 0 6px #000000; /* Smaller neon glow effect */
  white-space: pre-wrap;
  transition: text-shadow 0.2s ease-in-out; /* Smooth transition for text-shadow */
  line-height: 1.5; /* Adjust this value to increase/decrease line spacing */
  text-align: center;
  font-size: 20px;
}

/* Carousel styles */
.carousel-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.carousel-counter {
  font-family: 'Press Start 2P', cursive;
  color: #00ff00;
  margin-right: 10px;
}

.carousel-dots {
  display: flex;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-dot.active {
  background-color: #00cc00;
}

.grid-image {
  position: absolute; /* Ensure the image is positioned absolutely within the container */
  width: 130px; /* Set a fixed width for the image */
  height: 200px; /* Set a fixed height for the image */
}

/* Contract address container styles */
.contract-address {
  width: 90%; /* Matches the width of the main content container */
  max-width: 1145px; /* Matches the max-width of the main content container */
  margin: 20px auto; /* Center the container with margin auto */
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  padding: 10px 15px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners */
  font-size: 14px; /* Text size */
  color: #00ff00; /* Green text color */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-family: 'Press Start 2P', cursive; /* Retro font */
  z-index: 100; /* Lower than the navbar */
  text-align: center; /* Center-align text */
  box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.5); /* Green shadow effect */
}

.contract-address p {
  margin: 0;
  font-size: 14px; 
  word-break: break-all; 
}

.contract-address .contract-label {
  font-size: 12px;
  color: #aef20e; 
  margin-bottom: 5px;
}

.contract-address:hover {
  background-color: rgba(0, 0, 0, 1); /* Darker background on hover */
}

/* Clipboard popup styles */
.clipboard-popup {
  position: fixed; /* Fix to the top of the page */
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 30px 15px;
  border-radius: 8px;
  font-size: 14px;
  color: #00ff00;
  z-index: 2000; /* Ensure it appears above other elements */
  text-align: center;
  width: 80%; /* Adjust width as needed */
  max-width: 500px;
}

.navbar {
  z-index: 1100; /* Make sure it's higher than the contract address */
}

@media (max-width: 768px) {
  .contract-address {
    font-size: 12px; /* Smaller text size */
    flex-direction: column; /* Stack elements vertically */
  }

  .contract-address p,
  .contract-address .contract-label {
    font-size: 10px; /* Even smaller text size for very small screens */
  }

  .contract-address .contract-label {
    font-size: 8px; /* Even smaller label size */
  }
}

.airdrop-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1145px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-bottom: 20px;
}

.airdrop-content-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  width: 100%;
}

.airdrop-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.airdrop-text {
  flex: 1;
}

.airdrop-title {
  font-size: 24px;
  margin-bottom: 10px;
  word-spacing: -10px; /* Reduce the space between words */
}


.airdrop-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.airdrop-steps {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.airdrop-learn-more {
  display: flex;
  justify-content: center;
  width: 100%;
}

.learn-more-button {
  font-size: 24px;
  padding: 10px 20px;
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
}

.learn-more-button:hover {
  background-color: #00cc00;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .airdrop-content-container {
    grid-template-columns: 1fr;
  }

  .airdrop-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

.centered-text {
  text-align: center;
}

.gitbook-link {
  color: #00ff00;
  text-decoration: underline;
  margin-left: 5px;
}

.gitbook-link:hover {
  color: #aef20e;
  text-decoration: none;
}

/* Kickback section styles */
.kickback-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1145px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-bottom: 20px;
}

.kickback-content-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  width: 100%;
}

.kickback-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.kickback-text {
  flex: 1;
}

.kickback-title {
  font-size: 24px;
  margin-bottom: 10px;
  word-spacing: -10px; /* Reduce the space between words */
}

.kickback-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.kickback-steps {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.kickback-learn-more {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .kickback-content-container {
    grid-template-columns: 1fr;
  }

  .kickback-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

.kickback-info .kickback-text,
.kickback-info .kickback-title,
.kickback-info .kickback-description,
.kickback-info .kickback-steps {
  color: #ffffff; /* Set text color to white */
}

/* Add this new class to handle the hidden state */
.hidden {
  display: none !important;
}

/* Tokenomics section styles */
.tokenomics-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1145px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-bottom: 20px;
}

.tokenomics-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  width: 100%;
}

.tokenomics-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%; /* Ensure the section takes up full height of its container */
}

.tokenomics-text {
  flex: 1;
}

.tokenomics-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.white-text {
  color: #ffffff; /* White color for numbers and percentages */
}

.tokenomics-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
  color: #00ff00; /* Green color for the rest of the text */
  font-size: 16px;
  line-height: 3;
  word-spacing: -8px; /* Reduce the space between words */
}

.tokenomics-list ul {
  list-style-type: circle;
  padding-left: 20px;
  font-size: 16px;
  line-height: 2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tokenomics-content-container {
    grid-template-columns: 1fr;
  }

  .tokenomics-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .image-container {
    width: auto;
    height: 387px;
  }

  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    width: 100%;
    margin-bottom: 20px;
  }

  .text-image-section {
    flex-direction: column;
  }

  .text-image-section.reverse {
    flex-direction: column;
  }

  .section-image {
    width: 100%;
  }

  .contract-address {
    font-size: 12px;
    padding: 8px 12px;
  }

  .contract-address p {
    font-size: 12px;
  }

  .contract-address .contract-label {
    font-size: 10px;
  }

  /* Adjust chart size for smaller screens */
  #pieChart, #tokenomicsPieChart {
    max-width: 100%;
    height: auto;
    width: 80% !important; /* Ensure the chart takes up 80% of the screen width */
    margin: 20px auto;
  }
}

.landing-page.background-only .game-section {
  margin-top: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: none;
}

/* Hiding elements when background-only is true */
.landing-page.background-only .content,
.landing-page.background-only .grid-container,
.landing-page.background-only .features,
.landing-page.background-only .call-to-action,
.landing-page.background-only .section,
.landing-page.background-only .contract-address,
.landing-page.background-only .footer {
  display: none;
}

.landing-page.background-only .footer {
  display: none;
}

.congratulations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-top: 20px;
}

.congratulations-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.congratulations-text {
  color: #00ff00;
  font-size: 24px;
  text-align: center;
  font-family: 'Press Start 2P', cursive;
}  
