/* Referral Page Styles */
.referral-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00ff00; /* Retro green color */
    width: 100%;
    min-height: 100vh;
    padding-top: 70px; /* Adjust for the fixed navbar height */
    background: url('../assets/space-blue.png') center/cover no-repeat;
}

.content1 {
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 90%; /* Ensure it fits within the screen width */
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 48px;
    margin-bottom: 20px;
    text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 15px #000;
    word-wrap: break-word; /* Ensure long words break to the next line */
}

.description, .example-link, .url-shorteners, .referral-details {
    font-size: 24px;
    color: #ffffff;
    text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 15px #000;
    margin: 20px 0;
}

p {
    line-height: 1.5; /* Add line height for wrapped text */
    margin: 20px 0; /* Add padding between paragraphs */
}

.back-button {
    font-size: 24px;
    padding: 10px 20px;
    background-color: #00ff00;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.back-button:hover {
    background-color: #00cc00;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
}

.input-group {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group input {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    font-size: 18px;
    border: 2px solid #00ff00;
    border-radius: 5px;
    background-color: #000;
    color: #00ff00;
    text-align: center;
    outline: none;
}

.error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
}

.referral-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%; /* Ensure it fits within the container width */
}

.referral-link {
    font-size: 14px; /* Smaller font size */
    color: #00ff00;
    word-wrap: break-word; /* Ensure long words break to the next line */
    margin-bottom: 10px;
    width: 100%; /* Ensure it takes the full width of the container */
    max-width: 100%;
    text-align: center; /* Center align the text */
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8); /* Background for better readability */
    border: 2px solid #00ff00;
    border-radius: 5px;
}

.copy-button {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #00ff00;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #00cc00;
}

@media (max-width: 768px) {
    .content {
        width: 90%;
    }
}

.referral-details p, .url-shorteners p {
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 15px #000;
    margin: 20px 0; /* Add padding between paragraphs */
    line-height: 1.5; /* Add line height for wrapped text */
}
