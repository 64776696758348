/* Typing text container */
.typed-text-container {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  overflow: hidden; /* Ensure text stays within the container */
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
}

/* Typing text line container */
.typed-text-container {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  overflow: hidden; /* Ensure text stays within the container */
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
  font-size: 24px; /* Increased font size for larger text */
}


/* Typing text line */
.typed-line {
  color: #FFFFFF;
  font-family: 'Press Start 2P', cursive;
  margin: 1px 0; /* Add spacing between lines */
  text-shadow: 0 0 2px #00ffea, 0 0 3px #00ffea, 0 0 4px #00ffea; /* Smaller neon glow effect */
  display: inline-block;
  transition: text-shadow 0.2s ease-in-out; /* Smooth transition for text-shadow */
}

/* Duplicate line for continuous scrolling */
.typed-line.duplicate {
  position: absolute;
  left: 100%;
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .typed-line {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}
