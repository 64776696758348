.retro-button {
    font-family: 'Press Start 2P', sans-serif;
    font-size: 14px;
    color: #ffffff;
    background-color: #000000;
    border: 3px solid #ffffff;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    outline: none;
}

.retro-button:hover {
    background-color: #ffffff;
    color: #000000;
}
