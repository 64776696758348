/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
}

/* Airdrop Page Styles */
.airdrop-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00ff00; /* Retro green color */
    width: 100%;
    min-height: 100vh;
    background: url('../assets/space-red.png') center/cover no-repeat;
    padding-top: 70px; /* Adjust for the fixed navbar height */
}

.wallet-info-container, .contract-info-container {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    padding: 5px;
    /* border: 2px solid #00ff00; */
    border-radius: 5px;
    max-width: 325px; /* Ensure it doesn't overflow the screen */
    word-wrap: break-word; /* Break long words to keep text inside the container */
    overflow: hidden; /* Hide overflowing content */
    width: auto; /* Allow the width to adjust based on content */
    text-align: center; /* Center align text inside this container */
}

.wallet-info-container {
    top: 75px;
    right: 25px;
}

.contract-info-container {
    top: 75px;
    left: 25px;
}

.wallet-info, .contract-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00ff00;
    font-family: 'Press Start 2P', sans-serif;
    word-wrap: break-word; /* Break long words to keep text inside the container */
    width: 100%; /* Make sure it takes the full width of the parent */
}

.wallet-info p, .contract-info p {
    margin: 5px 0;
    font-size: 10px;
    word-wrap: break-word; /* Ensure long words break to the next line */
    width: 100%; /* Make sure it takes the full width of the parent */
}

.wallet-balance, .contract-address {
    display: flex;
    justify-content: center; /* Center justify the content */
    margin-top: 5px; /* Add some space at the top */
    width: 100%; /* Ensure it takes the full width of the parent */
}

.contract-button-container {
    margin-top: 10px; /* Add some space at the top */
}

.content2 {
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    /* border: 3px solid green; */
    border-radius: 10px;
    margin-bottom: 20px;
    width: 90%; /* Adjust width for better responsiveness */
    max-width: 1000px; /* Increase max-width for wider containers */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 48px;
    margin-bottom: 20px;
    word-wrap: break-word; /* Ensure long words break to the next line */
}

.phrases p, .claim-section p {
    font-size: 24px;
    color: #ffffff;
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00;
    margin: 20px 0;
    margin-top: 15px;
}

.cta-button {
    font-size: 24px;
    padding: 10px 20px;
    background-color: #00ff00;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.cta-button:hover {
    background-color: #00cc00;
}

.wallet-button-container {
    margin: 20px 0;
}

.wallet-button {
    background-color: #ffffff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.wallet-button:hover {
    background-color: #ffffff;
}

.claim-section {
    margin-top: 20px;
}

.claim-button {
    display: flex;
    justify-content: center; /* Center justify the claim button */
    margin-top: 20px; /* Add some space at the top */
}

.claim-button button, .claim-vested-button button {
    color: #fff; /* White text color */
    background-color: #020202; /* Dark background color */
    border: 2px solid #fff; /* White border */
    border-radius: 5px; /* Rounded corners */
    padding: 8px 20px; /* Adjust padding */
    font-family: 'Press Start 2P', sans-serif; /* Add pixel font */
    letter-spacing: 2.2px; /* Add spacing between letters */
    cursor: pointer; /* Add cursor pointer */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.claim-button button:hover, .claim-vested-button button:hover {
    background-color: #ffffff; /* Darker background color on hover */
}

.last-claim-timestamp, .unvested-amount {
    margin-top: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #000;
    border: 3px solid green;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    max-width: 90%; /* Ensure it fits within the screen width */
}

.modal-title {
    font-size: 36px;
    margin-bottom: 20px;
}

.modal-button {
    font-size: 24px;
    padding: 10px 20px;
    background-color: #00ff00;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-button:hover {
    background-color: #00cc00;
}

.modal-close {
    font-size: 24px;
    padding: 10px 20px;
    background-color: red;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-top: 10px;
}

.modal-close:hover {
    background-color: darkred;
}

.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 250px;
}

.notification-message {
    margin-bottom: 10px;
    font-size: 14px;
}

.notification-progress-bar {
    width: 100%;
    background-color: #555;
    border-radius: 5px;
    overflow: hidden;
}

.notification-progress {
    height: 10px;
    background-color: #4caf50;
    transition: width 0.3s;
}

@media (max-width: 1545px) {
    .wallet-info-container,
    .contract-info-container {
        position: static;
        margin: 10px 0;
        order: -1; /* Move to the top */
        width: 90%; /* Ensure it fits within the screen width */
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .content,
    .modal {
        width: 90%;
    }

    .contract-address,
    .wallet-info-container,
    .contract-info-container {
        max-width: 84%; /* Full width on very small screens */
    }
}

.claim-section {
    margin-top: 20px;
    text-align: left; /* Ensure left alignment */
    width: 100%; /* Ensure it takes full width */
    max-width: 1200px;
}

.claim-section ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0;
}

.claim-section li {
    font-size: 16px;
    margin: 20px 0;
    color: #ffffff;
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00;
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically align items */
}

/* Left-align text in airdrop details */
.airdrop-details ul {
    text-align: left;
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

/* Center the claim vested button */
.claim-vested-button {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Add some space at the top */
    margin-bottom: 20px;
}

/* Eligibility section styling */
.eligibility-section {
    margin-top: 30px;
    text-align: center; /* Center text alignment */
    width: 100%;
    max-width: 1200px; /* Increase max-width for wider containers */
    color: #ffffff;
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00;
}

.eligibility-section h2 {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center; /* Center align heading */
}

.eligibility-section p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left; /* Center align paragraph */
}

.eligibility-section .no-glow {
    text-shadow: none; /* Remove text shadow */
}

.eligible-chains-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
}

.chain-item {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border: .5px solid #00ff00;
    border-radius: 5px;
    text-align: center;
    color: #00ff00;
    font-family: 'Press Start 2P', cursive;
    overflow: hidden;
}

/* Referral section styling */
.referral-section {
    margin-top: 30px;
    text-align: center; /* Center text alignment */
    width: 100%;
    max-width: 1200px; /* Increase max-width for wider containers */
    color: #ffffff;
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00;
}

.referral-section h2 {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center; /* Center align heading */
}

.referral-section p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center; /* Center align paragraph */
    text-shadow: none; /* Remove text shadow */
}

.referral-button {
    display: flex;
    justify-content: center;
}

/* Progress bar styling */
.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #000;
    border: 1px solid #00ff00;
    overflow: hidden;
    margin-left: 10px; /* Adjust margin to align with text */
}

.progress {
    height: 100%;
    background-color: #00ff00;
    transition: width 0.3s;
}

/* Neon red text and shadow */
.neon-red {
    color: #ff0000;
    text-shadow: 0 0 2px #ff0000, 0 0 4px #ff0000, 0 0 6px #ff0000, 0 0 8px #ff0000;
}

.red-highlight {
    color: #ff0000;
    text-shadow: 0 0 2px #070707, 0 0 4px #070707, 0 0 6px #070707, 0 0 8px #070707;
}

.wrong-chain-message p, .not-eligible-message p {
    color: #ff0000;
    text-shadow: 0 0 2px #070707, 0 0 4px #070707, 0 0 6px #070707, 0 0 8px #070707;
    text-align: center;
}

.red-text {
    color: #ff0000;
}

.learn-more-button2 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.learn-more-button2 a {
    text-decoration: none;
}

.highlight-date {
    color: #00ff00; /* Bright yellow to stand out */
    font-weight: bold; /* Bold the text */
    text-shadow: 0 0 5px #000000, 0 0 5px #000000, 0 0 5px #000000; /* Add a glowing effect */
    background-color: rgba(0, 0, 0, 0.8); /* Slight background to make it more visible */
    padding: 5px 10px; /* Add padding for emphasis */
    border-radius: 5px; /* Slight rounding of the corners */
    display: inline-block; /* Keep the text inline while adding padding */
    text-align: center;
}



