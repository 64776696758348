.kickback-calculator-container {
  background: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  box-sizing: border-box;
}

.kickback-calculator-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.kickback-calculator-inputs label {
  display: block;
  margin-bottom: 5px;
  font-family: 'Press Start 2P', cursive;
}

.kickback-calculator-inputs input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #00ff00;
  border-radius: 5px;
  background-color: #000;
  color: #00ff00;
  font-family: 'Press Start 2P', cursive;
  box-sizing: border-box;
}

.kickback-calculator-results {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.kickback-calculator-results h2 {
  margin-bottom: 20px;
  font-family: 'Press Start 2P', cursive;
}

.kickback-calculator-results p {
  margin-bottom: 10px;
  font-family: 'Press Start 2P', cursive;
}

/* Media queries for small screens */
@media screen and (max-width: 768px) {
  .kickback-calculator-grid {
      grid-template-columns: 1fr;
      gap: 15px;
  }

  .kickback-calculator-inputs input {
      font-size: 12px;
      padding: 8px;
  }

  .kickback-calculator-results h2 {
      font-size: 18px;
  }

  .kickback-calculator-results p {
      font-size: 14px;
  }
}

/* Media query for screens smaller than 480px */
@media screen and (max-width: 480px) {
  .kickback-calculator-container h1 {
      font-size: 26px; /* Adjust the h1 size */
  }

  .kickback-calculator-inputs input {
      font-size: 10px;
      padding: 6px;
  }

  .kickback-calculator-results h2 {
      font-size: 16px;
  }

  .kickback-calculator-results p {
      font-size: 12px;
  }
}
