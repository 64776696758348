.notification {
    position: fixed;
    top: 940px; /* Position at the bottom */
    left: 20px; /* Position at the left */
    background-color: rgba(0, 0, 0, 0.8); /* Black with transparency */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    max-width: 250px; /* Ensure it doesn't exceed the set width */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items horizontally */
    text-align: center; /* Center align text */
}

.notification-message {
    font-size: 14px;
    word-wrap: break-word; /* Ensure long messages wrap within the box */
}

.notification-progress-bar {
    width: 100%;
    background-color: #555;
    border-radius: 5px;
    overflow: hidden;
    height: 5px; /* Adjusted height for the progress bar */
    margin-top: 5px;
}

.notification-progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s;
}

@media (max-width: 768px) {
    .notification {
        top: 100px; /* Position at the top for smaller screens */
        bottom: unset; /* Unset bottom position */
        left: 50%;
        transform: translateX(-50%); /* Center horizontally */
    }
}