.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 2px 20px;
  border-bottom: 3px solid green;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between buttons */
}

.navbar-logo {
  display: flex;
  align-items: center;
  font-family: 'Press Start 2P', cursive;
  color: #00ff00;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.navbar-logo-image {
  width: 70px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-right: 10px; /* Space between logo and text */
}

.navbar-button {
  font-family: 'Press Start 2P', cursive;
  color: #00ff00;
  background-color: #000;
  border: 3px solid green;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center; /* Center text within the buttons */
}

.navbar-button:hover {
  background-color: green;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
}

.navbar-toggle-icon {
  font-size: 24px;
  color: #00ff00;
}

/* Media query for screens smaller than 915px */
@media (max-width: 915px) {
  .navbar-right {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #000;
    border-top: 3px solid green;
  }

  .navbar-right.open {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-button {
    width: 100%;
    border: none;
    border-bottom: 3px solid green;
  }

  .navbar-button:last-child {
    border-bottom: none;
  }
}
