.screen-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  width: 100%;
  background-color: #000;
  color: #00ff00;
  padding: 20px 0;
  text-align: center;
  scroll-snap-align: end;
  position: relative;
  bottom: 0;
  left: 0;
}

.footer-text {
  margin: 0;
}

.social-media a img {
  width: 30px;
  margin: 0 10px;
  filter: invert(1); /* Invert the colors to make the icons white */
}

.gitbook-text {
  font-size: 12px; /* Smaller font size */
  color: #00ff00; /* Ensure the color matches the other text */
  display: inline-block; /* Optional: ensures the text behaves similarly to the images */
  margin: 0 10px; /* Optional: adds space around the text */
  position: relative; /* Allows adjustment of its position */
  top: -7px; /* Adjust this value to lift the text up */
}
